import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EcoClub/ecocls9/1.jpg';
import p2 from 'assests/Photos/Clubs/EcoClub/ecocls9/2.jpg';
import p3 from 'assests/Photos/Clubs/EcoClub/ecocls9/3.jpg';
import p4 from 'assests/Photos/Clubs/EcoClub/ecocls9/4.jpg';
import p5 from 'assests/Photos/Clubs/EcoClub/ecocls9/5.jpg';
import p6 from 'assests/Photos/Clubs/EcoClub/ecocls9/6.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const EcoClass92023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Role Play
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 9  &  Date: 13 JUNE 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Eco association organised a role play competition for the students of Class 9 on 13 June 2023. The topics for 
        the competition were ‘Energy Conservation and its Implementation in Automobile Industry’ and ‘Effects of the Wastes 
        from Textile Production.’
        <br></br>
        Roleplay helps in enhancing communication and social skills. Students acquired the skill of applying their
        learning, in real-life context. The competition provided an excellent platform for students to explore
        realistic situations by interacting with other students. It allowed them to act out their thoughts and
        feeling. At the end of the session, there was a question-and-answer round by the judges, thereby
        assessing their general awareness amongst the participants.
        <br></br>
        The programme was addressed by the Principal Ms Sheeja Manoj, who highlighted the importance of judicious use of
         resources, asked students to focus on ‘needs’ and not on ‘wants’. Young people can play an active role in 
         protecting and improving the environment by being aware of the changes occurring in the environment. The activity 
         kept the audience engaged and motivated by providing informative aspects of energy conservation and wastes from 
         textile. The students thoroughly enjoyed the activity and it proved to be a fun and valuable learning experience. 
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
Conservation is a state of harmony between men and land

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default EcoClass92023;